import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GoToWishListV2 from "./component";

const mapStateToProps = ({
    user: {
        isLoggedIn
    },
    config: {
        superAppConfig: {
            topBanner: {
                wishlist: wishlistConfig
            }
        }
    }
}) => ({
    isLoggedIn,
    wishlistConfig
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GoToWishListV2);
