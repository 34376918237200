import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import withWishlist from "../with-wishlist";
import Login from "../login";

//Constants
import { LOGIN_TITLE, NUMBER } from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/routes-constants";
import { SOURCES } from "../login/constants";

//Styles
import styles from "./styles.css";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

const GoToWishListV2 = ({
    isLoggedIn,
    wishlistConfig,
    wishlistCount = NUMBER.ZERO
}) => {

    const {
        wishlistIcon,
        wishlistCountTextBackgroundColor,
        wishlistCountTextColor
    } = wishlistConfig || {};

    const [showLogin, setShowLogin] = useState(false);

    const {customPush} = useCustomHistoryOperations();

    const onClickWishlist = async(e) => {
        e.preventDefault();
        if (!isLoggedIn) {
            setShowLogin(true);
        } else {
           await customPush(`/${ROUTE_PATHS.wishlist}`);
        }
        trackPlatformCustomEventsAEV2("wishlistClicked", { eventLabel: "Wishlist/Home Page" });
        trackPlatformCustomEventsAEV2("superAppHpWishlistClick", { eventLabel: wishlistCount });
    };

    const onLoginSuccess = async () => {
        await customPush(`/${ROUTE_PATHS.wishlist}`);
        setShowLogin(false);
    };

    const inlineStyle = {
        color: wishlistCountTextColor,
        background: wishlistCountTextBackgroundColor
    };

    return (
        <React.Fragment>
            <a onClick={onClickWishlist} href={"/"} styleName={"styles.wishlistWrap"}>
                <img
                    src={wishlistIcon}
                    alt="Wishlist"
                    width={24}
                    height={24}
                />
                {wishlistCount > NUMBER.ZERO && <span styleName={`styles.countText`} style={inlineStyle}>{wishlistCount}</span>}
            </a>
            {showLogin && <Login
                isVisible={showLogin}
                onClose={() => setShowLogin(false)}
                onSuccess={onLoginSuccess}
                loginSource={LOGIN_TITLE.WISHLIST}
                loginFor={SOURCES.WISHLIST} />}
        </React.Fragment>
    );
};

GoToWishListV2.propTypes = {
    isLoggedIn: PropTypes.bool,
    sourceName: PropTypes.string,
    wishlistConfig: PropTypes.object,
    wishlistCount: PropTypes.number
};

export default withWishlist(GoToWishListV2);
