/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// Icons
import LogoWhiteBg from "./images/logo-blue-text.svg";

// Components
import GoToWishlistV2 from "../go-to-wishlist-v2";

// Constants
import { COACH_DOWNLOAD_APP_URL, NUMBER } from "../../../constants/app-constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";

// Tracking
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";

// Utilities
import { getMobileOsType } from "../../../tracking/c2c-tracking-params";
import useLink from "../../../hooks/use-link";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import { b2cAllPageEvents } from "../../../tracking/msite/b2c-all-page-events";
import { uncategorizedEvents } from "../../../tracking/msite/uncategorized-events";

const SuperAppHpHeader = ({
    route: { name: routeName } = {},
    wishlistedCarsList = [],
    topBannerConfig
}) => {
    const history = useHistory();
    const { getLinkProps } = useLink();

    const onLogoClick = async () => {
        await yieldToMainThread();
        history.push("/");
        await yieldToMainThread();
        trackPlatformCustomEventsAEV2(b2cAllPageEvents.logoClick);
    };

    const onDownloadIconClick = async (e) => {
        await yieldToMainThread();
        e.preventDefault();
        await yieldToMainThread();
        window.location.href = COACH_DOWNLOAD_APP_URL;
        trackPlatformCustomEventsAEV2(uncategorizedEvents.b2cDownloadButtonShown, {
            eventCategory: routeName === ROUTE_NAME.HOME ? "Buy_used_cars_B2C_Homepage" : "Buy_used_cars_B2C_Listing_Page",
            eventLabel: `use_app_${getMobileOsType.ios() ? "iPhone" : "android"}`
        });
    };

    const {
        appDownload: appDownloadConfig = {}
    } = topBannerConfig || {};

    const {
        textColor,
        backgroundColor,
        downloadArrowIcon
    } = appDownloadConfig || {};

    const inlineStyles = {
        color: textColor,
        background: backgroundColor
    };

    return (
        <Fragment>
            <header styleName={"styles.header"}>
                <nav className="nav fixed-top" styleName={`styles.navCustom styles.transparentBg`} role="navigation">
                    <div>
                        <a {...getLinkProps(`/`, { onClickHandler: () => onLogoClick() })}>
                            <img src={LogoWhiteBg} width="50" height="24" alt="CARS24" />
                        </a>
                    </div>
                    <div styleName={"styles.navItems"}>
                        <GoToWishlistV2 wishlistCount={(wishlistedCarsList?.length || NUMBER.ZERO)} />
                        <a
                            onClick={onDownloadIconClick}
                            href={COACH_DOWNLOAD_APP_URL}
                            styleName={`styles.downloadButtonWrap styles.homeDownloadButtonWrap`}
                            style={inlineStyles}
                        >
                            Use App
                            <span styleName={`styles.downloadIconWrap`}>
                                <img src={downloadArrowIcon} />
                            </span>
                        </a>
                    </div>
                </nav>
            </header>
        </Fragment>
    );
};

SuperAppHpHeader.propTypes = {
    route: PropTypes.object,
    showBlueIcon: PropTypes.bool,
    wishlistIconColor: PropTypes.string,
    wishlistedCarsList: PropTypes.array,
    topBannerConfig: PropTypes.object
};

export default SuperAppHpHeader;
