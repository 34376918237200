import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import SuperAppHpHeader from "./component";

const mapStateToProps = ({
    cities: {
        selectedCity
    },
    myWishlist: {
        wishlistedCarsList = []
    } = {},
    config: {
        superAppConfig: {
            topBanner = {}
        }
    }
}) => ({
    selectedCity,
    wishlistedCarsList,
    topBannerConfig: topBanner
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SuperAppHpHeader));
